import React from "react";
import watervoorziening from "../assets/Projecten/watervoorzieningTart1.jpg";

const ProjectSection = () => {
  return (
    <section className="bg-white sm:py-[65px] pt-0 pb-12 headerSection">
      <div className="container mx-auto flex flex-col-reverse sm:flex-row-reverse md:mt-7 gap-6 sm:gap-0 ">
        {/* Left Text Section */}
        <div className=" justify-center space-y-8 text-center md:text-left px-15 w-full sm:w-1/2 pt-9">
          <h1 className="text-3xl md:text-4xl text-center sm:text-left font-bold text-gray-800">
            Onze Projecten
          </h1>
          <p className="text-gray-600 text-md text-wrap pr-0 sm:pr-20 mx-2 sm:mx-0">
          Bij de Laassara Foundation werken we aan verschillende projecten die de levenskwaliteit verbeteren. Ons recente project in Trart, nabij Corgo, richt zich op het toegankelijker maken van schoon water voor de bewoners door de bestaande waterput uit te breiden en leidingen aan te leggen.

Daarnaast zetten we ons in voor initiatieven op het gebied van gezondheidszorg en onderwijs. Uw steun maakt deze projecten mogelijk en helpt ons om blijvende veranderingen te realiseren.

Ontdek al onze projecten en maak samen met ons een verschil!
          </p>

          <a
            href="/projecten"
            className="bg-softBlue text-white px-6 py-3 rounded-lg text-lg hover:scale-105 hover:shadow-lg transition duration-300 inline-block mt-3"
          >
            Lees meer
          </a>
        </div>

        {/* Right Image Section */}
        <div className="flex bg-transparent md:pr-24">
          <img
            src={watervoorziening} // Replace with your image URL
            alt="Laassara Foundation"
            className=" w-full max-w-lg sm:rounded-md rounded-none"
          />
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
