import React from 'react'
import ProjectenHeader from '../components/Projecten/Projectheader';
import ProjectsList from '../components/Projecten/ProjectsList';

const Projecten = () => {
  return (
    <div>
      <ProjectenHeader />
      <ProjectsList />
    </div>
  )
}

export default Projecten
